import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Fade from "react-reveal/Fade"
import Favicon from "../assets/images/favicon.png"
import Buttons from "../components/common/button"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import handleFormSubmit from "../components/common/FormBuilder/Form-Function"
import SVGIcon from "../components/common/SVGIcon"
import { preventInitialZero } from "../components/common/util-functions"
import { downloadAsset } from "../components/utility/asset_download"
import { checkValidEmail } from "../components/utility/validation"
import "../styles/pages/report.scss"

const useBodyPadding = () => {
  useEffect(() => {
    if (window.innerWidth < 750) {
      document.body.style.padding = "0"
    }

    return () => {
      document.body.style.padding = ""
    }
  }, [])
}

const ReportsPage = () => {
  const [stage, setStage] = useState("first-page")
  useBodyPadding()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 750)
    }

    checkIsMobile()

    window.addEventListener("resize", checkIsMobile)

    return () => {
      window.removeEventListener("resize", checkIsMobile)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 750)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize) // Cleanup on unmount
  }, [])

  const [moduleNo, setModuleNo] = useState(0)
  const data = GrowthReport().SuperOps.pages[0]
  const allFields = data?.newField
  const seo = data?.seo
  const allModules = allFields.filter(
    _ => _.__typename === "SuperOps_MultipleCard"
  )

  const otherDatas = allFields.filter(_ => _.__typename === "SuperOps_Card")

  const stages = [
    "first-page",
    "user-input",
    "intro",
    "report-usage",
    "all-modules",
    "individual-module",
    "final-result",
  ]
  let moduleHashtags = []
  allModules.map(item => {
    moduleHashtags.push(
      item.cards[0].cardName.toLowerCase().replaceAll(" ", "-")
    )
  })

  useEffect(() => {
    const reportEmail = JSON?.parse(localStorage.getItem("report-email"))
    if (reportEmail) {
      if (checkValidEmail(reportEmail)) {
        const stageLevel = localStorage.getItem("Stage-level")
        const hashtagValue = window.location.href.split("#")[1]
        if (hashtagValue) {
          if (stageLevel && stages.includes(stageLevel)) {
            setStage(stageLevel)
            if (moduleHashtags.includes(hashtagValue)) {
              setStage("all-modules")
              window.location.href =
                window.location.href.split("#")[0] + "#all-modules"
            }
          } else {
            setStage("first-page")
            localStorage.removeItem("Stage-level")
            window.location.href = window.location.href.split("#")[0]
          }
        }
      } else {
        localStorage.removeItem("report-email")
        localStorage.removeItem("module-answers")
        localStorage.removeItem("Stage-level")
        window.location.href = window.location.href.split("#")[0]
      }
    } else {
      localStorage.removeItem("module-answers")
      localStorage.removeItem("Stage-level")
    }
  }, [])

  useEffect(() => {
    window.addEventListener("popstate", event => {
      const url = window.location.href
      if (url.split("#")[1] && stages.includes(url.split("#")[1])) {
        setStage(url.split("#")[1])
        localStorage.setItem("Stage-level", url.split("#")[1])
      }
      // else {
      //   console.log("hi")
      //   setStage("first-page")
      //   localStorage.removeItem("Stage-level")
      //   window.location.href = window.location.href.split("#")[0]
      // }
    })
  })

  return (
    <div className="report-growth">
      <Helmet>
        <title>{seo?.title}</title>
        <meta property="og:image" content={seo?.image?.url} />
        <meta name="description" content={seo?.description} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon} />
      </Helmet>

      <div className="mobile-version h-100">
        {stage === "first-page" ? (
          <FirstPage
            setStage={setStage}
            data={otherDatas[0]}
            isMobile={isMobile}
          />
        ) : (
          <></>
        )}
        {stage === "user-input" ? (
          <UserInput
            setStage={setStage}
            data={otherDatas[1]}
            isMobile={isMobile}
          />
        ) : (
          <></>
        )}
        {stage === "intro" ? (
          <Intro setStage={setStage} data={otherDatas[2]} isMobile={isMobile} />
        ) : (
          <></>
        )}
        {stage === "report-usage" ? (
          <ReportUsage
            setStage={setStage}
            data={otherDatas[3]}
            sMobile={isMobile}
          />
        ) : (
          <></>
        )}
        {stage === "all-modules" ? (
          <AllModules
            setStage={setStage}
            allModules={allModules}
            setModuleNo={setModuleNo}
          />
        ) : (
          <></>
        )}
        {stage === "individual-module" ? (
          <MobIndividualModule
            setStage={setStage}
            moduleNo={moduleNo}
            allModules={allModules}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="desktop-version">
        {stage === "first-page" ? (
          <FirstPage
            setStage={setStage}
            data={otherDatas[0]}
            isMobile={isMobile}
          />
        ) : (
          <></>
        )}
        {stage === "user-input" ? (
          <UserInput
            setStage={setStage}
            data={otherDatas[1]}
            isMobile={isMobile}
          />
        ) : (
          <></>
        )}

        {stage === "intro" ? (
          <Intro setStage={setStage} data={otherDatas[2]} />
        ) : (
          <></>
        )}

        {stage === "report-usage" ? (
          <ReportUsage
            setStage={setStage}
            data={otherDatas[3]}
            isMobile={isMobile}
          />
        ) : (
          <></>
        )}

        {stage === "all-modules" ? (
          <AllModules
            setStage={setStage}
            allModules={allModules}
            setModuleNo={setModuleNo}
          />
        ) : (
          <></>
        )}
        {stage === "individual-module" ? (
          <IndividualModule
            setStage={setStage}
            moduleNo={moduleNo}
            allModules={allModules}
          />
        ) : (
          <></>
        )}
        {stage === "final-result" ? (
          <FinalResult
            setStage={setStage}
            data={otherDatas[4]}
            allModules={allModules}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const FirstPage = ({ setStage, data, isMobile }) => {
  const firstBox = (
    <>
      <div
        id="first-page"
        className=" align-items-center justify-content-center first-page desktop mx-auto h-100"
        style={{ backgroundImage: `url(${data?.picture[0]?.url})` }}
      >
        <div className="parent d-flex justify-content-center">
          <div className="left">
            <h1 className="font-roboto font-white fw-bold fs-60-em mb30 down-to-up-1">
              {data.cardName}
            </h1>
            <p className="font-white fs-24 mb30 down-to-up-2">
              {data.cardDescription}
            </p>
            <a
              onClick={() => {
                setStage("user-input")
                localStorage.setItem("Stage-level", "user-input")
              }}
              href="#user-input"
              className="btn btn-primary-new white-new down-to-up-3"
            >
              {data.slug}
            </a>
          </div>
          <div className="right">
            <img src={data?.picture[1]?.url} className="w-100 down-to-up-1" />
          </div>
        </div>
      </div>

      <div
        id="first-page"
        className=" align-items-center justify-content-center first-page mx-auto  mobile"
      >
        <div className="parent">
          <div className="left">
            <h1 className="font-roboto heading font-white fw-bold mb24 down-to-up-1">
              {data?.cardName}
            </h1>
            <h6 className="font-white alt description mb24 down-to-up-2">
              {data?.cardDescription}
            </h6>
            <a
              onClick={() => {
                setStage("user-input")
                localStorage.setItem("Stage-level", "user-input")
              }}
              href="#user-input"
              className="btn btn-primary-new white-new down-to-up-3"
            >
              {data?.slug}
            </a>
          </div>
          <div className="right">
            <img src={data?.picture[2]?.url} className="w-100 down-to-up-3" />
          </div>
        </div>
      </div>
    </>
  )
  return <Skeleton firstBox={firstBox} removeSecondBox transparentBg />
}
const UserInput = ({ setStage, data, isMobile }) => {
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      value: "",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Business email",
      value: "",
      errorMessage: "Please enter a valid email",
    },
  ]

  const handleAfterSubmit = (res, items) => {
    if (res?.status == 200) {
      if (isMobile) {
        setStage("intro")
        localStorage.setItem("Stage-level", "intro")
        window.location.href = window.location.href.split("#")[0] + "#intro"
      } else {
        setStage("intro")
        localStorage.setItem("Stage-level", "intro")
        localStorage.removeItem("module-answers")
        window.location.href = window.location.href.split("#")[0] + "#intro"
      }
    }
  }

  const firstBox = (
    <div
      id="user-input"
      className={`user-input d-flex align-items-center justify-content-center ${
        isMobile ? "mobile" : "desktop h-100"
      }`}
    >
      <div className={`parent d-flex justify-content-between flex-wrap `}>
        <Fade duration={500}>
          <div className="left">
            <h1
              className={`font-roboto fw-bold font-white mb40 heading ${
                isMobile ? "text-center" : ""
              }`}
            >
              {data.cardName}
            </h1>
            <p
              className={`font-white description  ${
                isMobile ? "text-center mb50" : "mb24"
              }`}
            >
              {data.cardDescription}
            </p>
          </div>
        </Fade>
        <Fade duration={500}>
          <div className="right d-flex align-items-center justify-content-center">
            <div className="child prelative">
              <FormBuilder
                id="sops"
                formClassName="forms mt6"
                errorClassName="error-message"
                inputErrorClassName="error-active"
                data={formdata}
                extradata={
                  isMobile
                    ? { name: "submission_from_mobile", value: "yes" }
                    : null
                }
                buttonText="START MY ASSESSMENT"
                buttonClassName="primary-new"
                buttonId="reportSubmitButton"
                IpStackData
                endpoint={process.env.HUBSPOT_GROWTH_REPORT_ENDPOINT}
                url={process.env.HUBSPOT_GROWTH_REPORT_URL}
                formName="Growth report - SuperOps"
                localStorageEmail
                afterSubmit={(res, items) => handleAfterSubmit(res, items)}
                disallowCommonDomains
                noCaptchaValidation
              />
              <div className="term-wrapper">{parse(data.content[0]?.html)}</div>
              <div className="dis-wrapper">
                <p className="dis">Disclaimer</p>
              </div>
              <div className="dis-part">
                <p className="disclaimer">
                  The statistics and data presented in this report are for
                  informational purposes only. While every effort has been made
                  to ensure accuracy, these figures should not be relied upon as
                  the sole basis for decision-making. Readers are encouraged to
                  conduct independent verification or seek professional advice
                  where necessary.
                </p>
              </div>
              <div className="tip-img">
                <img
                  src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/PYmITvv5Ti6m5lVya57i"
                  alt="vector"
                  w-100
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )

  return <Skeleton firstBox={firstBox} transparentBg removeSecondBox />
}
const Final = ({ data }) => {
  const firstBox = (
    <div
      id="thankyou-mob"
      className="thankyou-mob d-flex align-items-center justify-content-center h-100 mobile"
    >
      <div className="parent d-flex justify-content-between flex-wrap">
        <div className="left">
          <h1 className="font-roboto fw-bold font-white mb40 heading text-center mx-auto mt50">
            {data.cardName}
          </h1>
          <p className="font-white description text-center">
            {data.heading[0]}
          </p>
          <a
            className="btn btn-primary-new white-new"
            onClick={e => {
              e.preventDefault()
              downloadAsset(data.image[0].url, data.text)
            }}
          >
            {data.slug}
          </a>
          <p className="font-white description text-center mb50 mt50">
            {data.title[0]}
          </p>
        </div>
      </div>
    </div>
  )
  return <Skeleton firstBox={firstBox} transparentBg removeSecondBox />
}
const Intro = ({ setStage, data }) => {
  const firstBox = (
    <div id="intro" className="d-flex align-items-center intro mx-auto h-100">
      <div className="d-flex into align-items-start justify-content-between">
        <Fade duration={500}>
          <div className="left">
            <h1 className="font-roboto fw-bold fs-40-em title">
              {data.cardName}
            </h1>
            <div className="img-wrapper">
              <img src={data.image[0].url} alt="vector" className="w-100" />
            </div>
          </div>
        </Fade>
        <Fade duration={500}>
          <div className="right">
            <p className="font-roboto fw-bold fs-32-em">
              {data.cardDescription}
            </p>
            <p className="fs-24">{data.heading[0]}</p>
          </div>
        </Fade>
      </div>
    </div>
  )
  const rightBox = (
    <a
      onClick={() => {
        setStage("report-usage")
        localStorage.setItem("Stage-level", "report-usage")
      }}
      href="#report-usage"
      className="continue-btn cursor-pointer fs-16-em fw-bold"
    >
      CONTINUE
    </a>
  )
  return <Skeleton firstBox={firstBox} rightBox={rightBox} />
}

const ReportUsage = ({ setStage, data, isMobile }) => {
  const firstBox = (
    <div className="d-flex  report-usage mx-auto h-100">
      <div className={`d-flex rep-usg   ${isMobile ? "" : ""}`}>
        <Fade duration={500}>
          <div className="left">
            <h1 className="font-roboto fw-bold fs-40-em">{data.cardName}</h1>
            <hr className="line"></hr>
          </div>
        </Fade>
        <Fade duration={500}>
          <div className="d-flex flex-wrap gap-24 right">
            {data.heading.map((item, ind) => {
              return (
                <div className="card">
                  <p className="text-pink fs-16-em fw-bold tag">{`STEP ${ind +
                    1}`}</p>
                  <p className="font-roboto fw-bold fs-24">{item}</p>
                </div>
              )
            })}
          </div>
        </Fade>
      </div>
    </div>
  )
  const rightBox = (
    <a
      onClick={() => {
        setStage("all-modules")
        localStorage.setItem("Stage-level", "all-modules")
      }}
      href="#all-modules"
      className="continue-btn cursor-pointer fs-16-em fw-bold"
    >
      CONTINUE
    </a>
  )
  return <Skeleton firstBox={firstBox} rightBox={rightBox} />
}

const AllModules = ({ setStage, allModules, setModuleNo }) => {
  const [moduleAnswers, setModuleAnswers] = useState(
    JSON.parse(localStorage.getItem("module-answers")) ?? {}
  )

  const [moduleNumber, setModuleNumber] = useState(
    JSON.parse(localStorage.getItem("module-answers")) ?? 0
  )

  const firstBox = (
    <div
      id="all-modules"
      className="d-flex justify-content-center gap-16 flex-wrap all-modules mx-auto mt50 mb50"
    >
      {allModules.map((item, idx) => {
        const moduleName = item.cards[0].cardName
          .toLowerCase()
          .replaceAll(" ", "-")
        return (
          <Fade duration={500} delay={idx * 100}>
            <div
              className={` card position-relative ${
                Object.keys(moduleNumber)?.length >= idx ? "cur-pointer" : ""
              }`}
              onClick={() => {
                if (Object.keys(moduleNumber)?.length >= idx) {
                  setStage("individual-module")
                  setModuleNo(idx + 1)
                  localStorage.setItem("Stage-level", "individual-module")
                  localStorage.setItem("Module-number", idx + 1)
                  window.location.href = `#${moduleName}`
                }
              }}
            >
              <div
                className={` ${
                  Object.keys(moduleNumber)?.length < idx
                    ? "pointer-events-none translucent"
                    : "live"
                }`}
              >
                <a
                  // onClick={() => {
                  //   setStage("individual-module")
                  //   setModuleNo(idx + 1)
                  //   localStorage.setItem("Stage-level", "individual-module")
                  //   localStorage.setItem("Module-number", idx + 1)
                  // }}
                  // href={`#${moduleName}`}
                  className="link "
                >
                  <div className="position-absolute tilt-arrow">
                    <SVGIcon name="rightArrTilt" />
                  </div>

                  <p className="text-pink fw-bold">{`MODULE ${idx + 1}`}</p>
                  <p className="font-roboto fw-bold fs-32-em title deep-purple">
                    {item.cards[0].cardName}
                  </p>
                  {moduleAnswers.hasOwnProperty(moduleName) ? (
                    <div className="d-flex gap-8 align-items-center">
                      <SVGIcon name="greenTick" className="tick-logo" />
                      <p className="tag m-0">Completed</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            </div>
          </Fade>
        )
      })}
    </div>
  )
  return <Skeleton firstBox={firstBox} removeSecondBox />
}

const IndividualModule = ({ setStage, moduleNo, allModules }) => {
  let moduleNum = moduleNo
  if (moduleNo) {
  } else moduleNum = JSON.parse(localStorage.getItem("Module-number"))

  if (!moduleNum) {
    window.location.href = window.location.href.split("#")[0] + "#all-modules"
  }
  const reportUserEmail = JSON?.parse(localStorage.getItem("report-email"))
  const [reportLink, setReportLink] = useState("")

  useEffect(() => {
    const currentOrigin = window.location.origin
    const link = `${currentOrigin}/result?email=${reportUserEmail}`
    setReportLink(link)
  }, [reportUserEmail])

  const ThisModuleContent = allModules[moduleNum - 1]
  // console.log("ThisModuleContent ", ThisModuleContent)
  const [type, setType] = useState(ThisModuleContent?.cards[0].slug)
  const [step, setStep] = useState(1)

  const moduleScores =
    ThisModuleContent?.cards[ThisModuleContent?.cards?.length - 2]?.multiChoice

  const moduleOptions =
    ThisModuleContent?.cards[ThisModuleContent?.cards?.length - 2]?.multiOption

  const scoreRecommendations =
    ThisModuleContent?.cards[ThisModuleContent?.cards?.length - 1]?.multiOption

  const [totalScore, setTotalScore] = useState(0)

  // console.log("totalScore OUTSIDE", totalScore)

  const [answers, setAnswers] = useState()
  const [completed, setCompleted] = useState(false)

  const reportModuleAnswers =
    JSON.parse(localStorage.getItem("module-answers")) ?? {}

  useEffect(() => {
    const moduleName = ThisModuleContent?.cards[0]?.cardName
      .toLowerCase()
      .replaceAll(" ", "-")
    setAnswers(reportModuleAnswers[moduleName] ?? {})
    if (reportModuleAnswers?.hasOwnProperty(moduleName)) setCompleted(true)
  }, [])

  const handleOptionChange = (questionId, optInd) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: optInd,
    }))
  }

  // const calculateModuleScore = () => {
  //   let total = 0
  //   console.log("moduleScores", moduleScores)
  //   moduleScores.forEach((score, ind) => {
  //     console.log(
  //       "Number(score.option[answers[ind]]",
  //       Number(score.option[answers[ind]])
  //     )
  //     total += Number(score.option[answers[ind]])
  //   })
  //   console.log("total ", total)
  //   setTotalScore(total)

  //   const reportModuleAnswers =
  //     JSON.parse(localStorage.getItem("module-answers")) ?? {}

  //   reportModuleAnswers[
  //     ThisModuleContent.cards[0].cardName.toLowerCase().replaceAll(" ", "-")
  //   ] = answers

  //   localStorage.setItem("module-answers", JSON.stringify(reportModuleAnswers))

  //   return total
  // }
  const [overallScore, setOverallScore] = useState(0)
  // const moduleAnswers = JSON.parse(localStorage.getItem("module-answers"))
  // console.log("moduleAnswers ", moduleAnswers)
  // useEffect(() => {
  //   console.log("useeffect overallScore: ", overallScore)
  // }, [overallScore])

  const sendHubspotData = tempScore => {
    // console.log(" sendHubspotData tempScore: ", tempScore)
    let final_data = ThisModuleContent?.cards[
      ThisModuleContent?.cards?.length - 2
    ].text.map((ques, idx) => {
      return {
        name: `${ques.replaceAll(/[^a-zA-Z0-9]/g, "_").toLowerCase()}`,
        value: moduleOptions[idx].option[answers[idx]],
      }
    })
    const reportEmail = JSON?.parse(localStorage.getItem("report-email"))
    if (reportEmail) {
      final_data.push({ name: "email", value: reportEmail })
      final_data.push({
        name: "module_name",
        value: ThisModuleContent?.cards[0]?.cardName,
      })
      if (moduleNum == allModules?.length) {
        final_data.push({ name: "completed", value: "yes" })
        final_data.push({ name: "total_report_score", value: tempScore })
        final_data.push({ name: "report_page_link", value: reportLink })
      }
      handleFormSubmit(
        final_data,
        process.env.HUBSPOT_GROWTH_REPORT_ENDPOINT,
        process.env.HUBSPOT_GROWTH_REPORT_URL,
        "Report survey - SuperOps"
      )
    } else {
      localStorage.removeItem("module-answers")
      window.location.href = window.location.href.split("#")[0]
    }
  }

  const handleAfterSubmit = () => {
    if (ThisModuleContent.cards[step - 1]?.slug === "get tech count") {
      const reportEmail = JSON?.parse(localStorage.getItem("report-email"))
      const techniciansData = [
        {
          name: "email",
          value: reportEmail,
        },
        {
          name: "number_of_technicians",
          value: totalTechnicians,
        },
      ]
      handleFormSubmit(
        techniciansData,
        process.env.HUBSPOT_GROWTH_REPORT_ENDPOINT,
        process.env.HUBSPOT_GROWTH_REPORT_URL,
        "Report survey - SuperOps"
      )
      if (error) return
    }
    if (ThisModuleContent.cards[step - 1]?.slug === "assessment") {
      let tempScore = 0
      if (
        ThisModuleContent.cards[step - 1]?.text.length ===
        Object.keys(answers).length
      ) {
        // ThisModuleContent.cards[step]?.slug
        //   ? setType(ThisModuleContent.cards[step]?.slug)
        //   :
        reportModuleAnswers[
          ThisModuleContent.cards[0].cardName.toLowerCase().replaceAll(" ", "-")
        ] = answers

        localStorage.setItem(
          "module-answers",
          JSON.stringify(reportModuleAnswers)
        )
        if (moduleNum == allModules?.length) {
          // setStage("final-result")
          // window.location.href =
          //   window.location.href.split("#")[0] + "#final-result"
          // localStorage.setItem("Stage-level", "final-result")
          // const moduleAnswers = JSON.parse(
          //   localStorage.getItem("module-answers")
          // )
          // console.log("moduleAnswers ", moduleAnswers)
          allModules?.map((item, idx) => {
            let moduleId = item?.cards?.[0].cardName
              ?.replaceAll(" ", "-")
              ?.toLowerCase()
            // console.log("moduleId inside ", moduleId)
            Object.entries(reportModuleAnswers[moduleId])?.map(
              ([key, value]) => {
                tempScore += Number(
                  item?.cards?.[item?.cards?.length - 2]?.multiChoice?.[key]
                    ?.option?.[value]
                )
              }
            )
          })
          !completed && sendHubspotData(tempScore)
          const email = JSON?.parse(localStorage.getItem("report-email"))
          window.location.href = `/result?email=${email}`
          // console.log("checker")
        } else {
          setStage("all-modules")
          !completed && sendHubspotData()
        }
        // setStep(step + 1)
        // calculateModuleScore()
        setShowError(false)
      } else {
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      if (ThisModuleContent.cards[step]?.slug) {
        setType(ThisModuleContent.cards[step]?.slug)
      }
      // else {
      //   if (moduleNum == allModules?.length) {
      //     setStage("final-result")
      //     // window.location.href =
      //     //   window.location.href.split("#")[0] + "#final-result"
      //     // localStorage.setItem("Stage-level", "final-result")
      //     const email = localStorage.getItem("report-email")
      //     window.location.href = `/result?email=${email}`
      //   } else {
      //     window.location.href =
      //       window.location.href.split("#")[0] + "#all-modules"
      //     setStage("all-modules")
      //   }
      // }
      if (!error) {
        setStep(step + 1) // Move to next step only if there's no error
      }
    }
  }

  const cardContent = ThisModuleContent?.cards[step - 1]

  const [totalTechnicians, setTotalTechnicians] = useState(1)
  const [error, setError] = useState("")
  const checkTechRange = value => {
    return value >= 1 && value <= 1000
  }

  const [showError, setShowError] = useState(false)
  const formatNumber = num => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`
    }
    return num
  }
  const firstBox = (
    <div className="d-flex flex-column individual-module h-100 mx-auto">
      <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
        <h3 className="font-roboto fw-bold module-heading">
          {cardContent.cardName}
        </h3>
        {type !== "result" ? (
          <div className="d-flex justify-content-between align-items-center gap-8">
            {ThisModuleContent?.cards.slice(0, -1).map((item, idx) => {
              return (
                <p
                  className={`${
                    idx + 1 === step ? "live fw-bold" : "fw-500"
                  } cursor-pointer step  m-0`}
                  onClick={() => {
                    setType(ThisModuleContent?.cards[idx]?.slug)
                    setStep(idx + 1)
                  }}
                >{`Step ${idx + 1}`}</p>
              )
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
      {type === "Type 1" ? (
        <Fade duration={500}>
          <div className="type-1 h-100">
            <p className="tag fw-600 fs-24 text-pink">
              {cardContent?.cardDescription}
            </p>

            <Row
              className={`d-flex h-90 align-items-center ${
                cardContent?.image.length ? "" : ""
              }`}
            >
              {cardContent?.content.map(item => {
                return (
                  <Col
                    lg={cardContent?.image?.length ? 4 : 6}
                    className="content-cover"
                  >
                    {parse(item.html)}
                  </Col>
                )
              })}
              {cardContent?.image.length ? (
                <Col lg={4}>
                  <img src={cardContent?.image[0]?.url} className="w-100" />
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "Type 2" ? (
        <Fade duration={500}>
          <div className="type-2 h-100 ">
            <p className="tag fs-24  fw-600 text-pink">
              {cardContent?.cardDescription}
            </p>

            <div className="d-flex gap-24 h-90 align-items-center">
              <Col lg={5} className="content-cover">
                {parse(cardContent?.content[0]?.html)}
              </Col>
              <Col lg={4}>
                <div className="tip-box">
                  <p className="fw-600">{cardContent?.text[0]}</p>
                  {parse(cardContent?.content[1]?.html)}
                </div>
              </Col>
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "Type 3" ? (
        <Fade duration={500}>
          <div className="type-3 h-100">
            <p className="tag fw-600  fs-24 text-pink">
              {cardContent?.cardDescription}
            </p>
            <div className="d-flex align-items-center justify-content-between h-90">
              <Col lg={cardContent?.image?.length ? 6 : 11} className="fs-24">
                {parse(cardContent?.content[0]?.html)}
              </Col>
              {cardContent?.image[0] ? (
                <Col lg={4}>
                  <img src={cardContent?.image[0]?.url} className="w-100" />
                </Col>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "Type 4" ? (
        <Fade duration={500}>
          <div className="type-4 my-auto">
            {cardContent?.content?.map((item, idx) => {
              return (
                <div className="d-flex align-items-center justify-content-center box mx-auto">
                  <div className="ques-no fs-32-em fw-bold">{`0${idx +
                    1}`}</div>
                  {parse(item?.html)}
                </div>
              )
            })}
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "Type 5" ? (
        <Fade duration={500}>
          <div className="type-5 h-100 d-flex flex-column">
            <p className="tag fw-600 fs-24 text-pink">
              {cardContent?.cardDescription}
            </p>
            <div className="d-flex gap-8 justify-content-center my-auto">
              {cardContent?.content?.map(item => {
                return <div className="tip-box">{parse(item?.html)}</div>
              })}
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "get tech count" ? (
        <Fade duration={500}>
          <div className="get-tech d-flex align-items-center h-100 my-auto">
            <div className="child">
              <p className="fs-32-em mb80">{cardContent?.text[0]}</p>
              <p className="font-roboto fw-bold fs-24">
                {cardContent?.text[1]}
              </p>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-24">
                  <input
                    type="number"
                    value={totalTechnicians}
                    min={1}
                    max={1000}
                    className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                    onChange={e => {
                      let tempValue = e.target.value

                      if (tempValue === "") {
                        setTotalTechnicians("")
                        setError("Minimum requirement: 1 technician")
                        return
                      }

                      if (checkTechRange(tempValue)) {
                        setTotalTechnicians(preventInitialZero(tempValue))
                        setError("")
                      }
                    }}
                  />
                  <Buttons
                    theme="btn btn-primary-new white-new"
                    text={cardContent?.text[2]}
                    onClick={() => handleAfterSubmit(totalTechnicians)}
                  />
                </div>
                {error && <p className="text-danger mt-2">{error}</p>}{" "}
                {/* Show error */}
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "calc tech count" ? (
        <Fade duration={500}>
          <div className="calc-tech d-flex align-items-center h-100 my-auto">
            <div className="d-flex justify-content-between align-items-center">
              <div className="left">{parse(cardContent?.content[0]?.html)}</div>
              <div className="center">
                <img src={cardContent?.image[0]?.url} className="w-100" />
              </div>
              <div className="right">
                {parse(cardContent?.content[1]?.html)}
                <span className="fw-bold">{` ${formatNumber(
                  totalTechnicians * 200000
                )} ,`}</span>
                {parse(cardContent?.content[2]?.html)}
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}

      {type === "recommend" ? (
        <Fade duration={500}>
          <div className="recommend my-auto">
            <div className="d-flex justify-content-between">
              {cardContent?.text?.map((item, idx) => {
                return (
                  <div
                    className={`box ${
                      cardContent?.text?.length === 3
                        ? "width-32-percent"
                        : "width-24-percent"
                    }`}
                  >
                    {cardContent?.image[idx] && (
                      <div className="icon-wrap mb-20-em">
                        <img
                          src={cardContent?.image[idx]?.url}
                          className="w-100"
                        />
                      </div>
                    )}
                    <p className="fw-600 fs-24 pb-20-em mb-20-em text">
                      {item}
                    </p>
                    <p className="fs-16-em">
                      {parse(cardContent?.content[idx]?.html)}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "recommend-2" ? (
        <Fade duration={500}>
          <div className="recommend-2 my-auto">
            {cardContent?.cardDescription ? (
              <p className="tag  fs-24 fw-600 text-pink">
                {cardContent?.cardDescription}
              </p>
            ) : (
              <></>
            )}
            <div className="recommend-2 d-flex gap-24">
              {cardContent?.text[0] ? (
                <div className="left-box box fw-bold">
                  <p>{cardContent?.text[0]}</p>
                </div>
              ) : (
                <></>
              )}

              <div className="right-box box">
                {parse(cardContent?.content[0]?.html)}
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "recommend-3" ? (
        <Fade duration={500}>
          <div className="recommend-3 ">
            <p className="tag fw-600 fs-24 text-pink">
              {cardContent?.cardDescription}
            </p>
            <div className="d-flex justify-content-between">
              {cardContent?.text?.map((item, idx) => {
                return (
                  <div
                    className={`box  ${
                      cardContent?.text?.length === 3
                        ? "width-32-percent"
                        : "width-24-percent"
                    }`}
                  >
                    <div className="above-box d-flex align-items-center gap-24 mx-auto">
                      {cardContent?.image[idx] && (
                        <div className="icon-wrap mb-20-em">
                          <img
                            src={cardContent?.image[idx]?.url}
                            className="w-100"
                          />
                        </div>
                      )}
                      <p className="fw-600 fs-24 m-0 text">{item}</p>
                    </div>
                    <div className="below-box">
                      <p className="fs-16-em">
                        {parse(cardContent?.content[idx]?.html)}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "assessment" ? (
        <Fade duration={500}>
          <div className="h-100 d-flex flex-column my-auto gap-100">
            <p className="tag fw-600 fs-24 text-pink">
              {cardContent?.cardDescription}
            </p>
            <div className="assessment w-100">
              {cardContent.text.map((ques, idx) => {
                return (
                  <Row className="flex-wrap mb-40-em">
                    <Col lg={6}>
                      <div className="d-flex gap-16">
                        <span className="ques-no font-roboto fs-24 fw-600 mr15">{`0${idx +
                          1}`}</span>
                        <p className="font-roboto fs-24 fw-600">{ques}</p>
                      </div>
                    </Col>
                    <Col
                      lg={5}
                      className="d-flex align-items-center gap-24 justify-content-between"
                    >
                      {cardContent?.multiOption[idx].option.map(
                        (option, optInd) => {
                          return (
                            <div className="position-relative  radio-wrapper cursor-pointer">
                              <input
                                type="radio"
                                id={`${option}${idx}`}
                                name={`${option}${idx}`}
                                value={option}
                                checked={answers[idx] === optInd}
                                onChange={() => handleOptionChange(idx, optInd)}
                                className=" m-0"
                                disabled={completed}
                              />
                              <label
                                for={`${option}${idx}`}
                                className="checkbox-label d-flex align-items-center"
                              >
                                <span className="checkbox-name">
                                  <p className="one m-0 fw-500 fs-20-em">
                                    {option}
                                  </p>
                                </span>
                              </label>
                            </div>
                          )
                        }
                      )}
                    </Col>
                  </Row>
                )
              })}
            </div>
          </div>
        </Fade>
      ) : (
        <></>
      )}
      {type === "result" ? (
        <Fade duration={500}>
          <div className="d-flex results justify-content-between my-auto">
            <div
              lg={scoreRecommendations.length === 2 ? 4 : 3}
              className={`score-box box text-center ${
                cardContent?.multiOption?.length === 2
                  ? "width-32-percent"
                  : "width-24-percent"
              }`}
            >
              <p className="fs-24 fw-bold mb50">
                {"Your results are in, Superstar!"}
              </p>
              <p className="fs-24 m-0 fw-bold">{"Score"}</p>
              <h1 className="fw-bold">{totalScore}</h1>
            </div>
            {scoreRecommendations.map((item, ind) => {
              return (
                <div
                  lg={scoreRecommendations.length === 2 ? 4 : 3}
                  className={`result-box box ${
                    cardContent?.multiOption?.length === 2
                      ? "width-32-percent"
                      : "width-24-percent"
                  }`}
                >
                  <p className="fs-16-em">{item.option[answers[ind]]}</p>
                </div>
              )
            })}
          </div>
        </Fade>
      ) : (
        <></>
      )}
    </div>
  )

  const leftBox = (
    <div className="d-flex w-80-percent">
      <p className="font-white fs-16-em module-name m-0 fw-600">{`MODULE ${moduleNum}`}</p>
      <div className="d-flex align-items-center gap-8 progress-div">
        <p className="m-0 fs-16-em font-white fw-600">{"PROGRESS"}</p>
        <progress
          id="file"
          className="module-progress w-100"
          value={Object.keys(reportModuleAnswers)?.length}
          max={allModules.length}
        />
      </div>
    </div>
  )

  const rightBox = (
    <div className="position-relative w-100 d-flex justify-content-center">
      <a
        onClick={() => handleAfterSubmit(totalTechnicians)}
        className="continue-btn cursor-pointer fs-16-em fw-bold"
      >
        {"CONTINUE"}
      </a>
      <p
        className={`error-message error-text ${
          showError ? "fade-in-effect" : "fade-out-effect"
        }`}
      >
        {"Please answer all the above questions"}
      </p>
    </div>
  )

  return <Skeleton firstBox={firstBox} rightBox={rightBox} leftBox={leftBox} />
}
const MobIndividualModule = ({ setStage, moduleNo, allModules }) => {
  let moduleNum = moduleNo
  if (moduleNo) {
  } else moduleNum = JSON.parse(localStorage.getItem("Module-number"))

  if (!moduleNum) {
    window.location.href = window.location.href.split("#")[0] + "#all-modules"
  }
  const reportUserEmail = JSON?.parse(localStorage.getItem("report-email"))
  const [reportLink, setReportLink] = useState("")

  useEffect(() => {
    const currentOrigin = window.location.origin
    const link = `${currentOrigin}/result?email=${reportUserEmail}`
    setReportLink(link)
  }, [reportUserEmail])

  const ThisModuleContent = allModules[moduleNum - 1]
  // console.log("ThisModuleContent ", ThisModuleContent)
  const [type, setType] = useState(ThisModuleContent?.cards[0].slug)
  const [step, setStep] = useState(1)

  const moduleScores =
    ThisModuleContent?.cards[ThisModuleContent?.cards?.length - 2]?.multiChoice

  const moduleOptions =
    ThisModuleContent?.cards[ThisModuleContent?.cards?.length - 2]?.multiOption

  const scoreRecommendations =
    ThisModuleContent?.cards[ThisModuleContent?.cards?.length - 1]?.multiOption

  const [totalScore, setTotalScore] = useState(0)

  // console.log("totalScore OUTSIDE", totalScore)

  const [answers, setAnswers] = useState()
  const [completed, setCompleted] = useState(false)

  const reportModuleAnswers =
    JSON.parse(localStorage.getItem("module-answers")) ?? {}

  useEffect(() => {
    const moduleName = ThisModuleContent?.cards[0]?.cardName
      .toLowerCase()
      .replaceAll(" ", "-")
    setAnswers(reportModuleAnswers[moduleName] ?? {})
    if (reportModuleAnswers?.hasOwnProperty(moduleName)) setCompleted(true)
  }, [])

  const handleOptionChange = (questionId, optInd) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: optInd,
    }))
  }

  // const calculateModuleScore = () => {
  //   let total = 0
  //   console.log("moduleScores", moduleScores)
  //   moduleScores.forEach((score, ind) => {
  //     console.log(
  //       "Number(score.option[answers[ind]]",
  //       Number(score.option[answers[ind]])
  //     )
  //     total += Number(score.option[answers[ind]])
  //   })
  //   console.log("total ", total)
  //   setTotalScore(total)

  //   const reportModuleAnswers =
  //     JSON.parse(localStorage.getItem("module-answers")) ?? {}

  //   reportModuleAnswers[
  //     ThisModuleContent.cards[0].cardName.toLowerCase().replaceAll(" ", "-")
  //   ] = answers

  //   localStorage.setItem("module-answers", JSON.stringify(reportModuleAnswers))

  //   return total
  // }
  const [overallScore, setOverallScore] = useState(0)
  // const moduleAnswers = JSON.parse(localStorage.getItem("module-answers"))
  // console.log("moduleAnswers ", moduleAnswers)
  // useEffect(() => {
  //   console.log("useeffect overallScore: ", overallScore)
  // }, [overallScore])

  const sendHubspotData = tempScore => {
    // console.log(" sendHubspotData tempScore: ", tempScore)
    let final_data = ThisModuleContent?.cards[
      ThisModuleContent?.cards?.length - 2
    ].text.map((ques, idx) => {
      return {
        name: `${ques.replaceAll(/[^a-zA-Z0-9]/g, "_").toLowerCase()}`,
        value: moduleOptions[idx].option[answers[idx]],
      }
    })
    const reportEmail = JSON?.parse(localStorage.getItem("report-email"))
    if (reportEmail) {
      final_data.push({ name: "email", value: reportEmail })
      final_data.push({
        name: "module_name",
        value: ThisModuleContent?.cards[0]?.cardName,
      })
      if (moduleNum == allModules?.length) {
        final_data.push({ name: "completed", value: "yes" })
        final_data.push({ name: "total_report_score", value: tempScore })
        final_data.push({ name: "report_page_link", value: reportLink })
      }
      handleFormSubmit(
        final_data,
        process.env.HUBSPOT_GROWTH_REPORT_ENDPOINT,
        process.env.HUBSPOT_GROWTH_REPORT_URL,
        "Report survey - SuperOps"
      )
    } else {
      localStorage.removeItem("module-answers")
      window.location.href = window.location.href.split("#")[0]
    }
  }
  const [isSubmitted, setIsSubmitted] = useState(false)
  const handleTechSubmit = () => {
    if (totalTechnicians) {
      const reportEmail = JSON?.parse(localStorage.getItem("report-email"))
      const techniciansData = [
        {
          name: "email",
          value: reportEmail,
        },
        {
          name: "number_of_technicians",
          value: totalTechnicians,
        },
      ]
      handleFormSubmit(
        techniciansData,
        process.env.HUBSPOT_GROWTH_REPORT_ENDPOINT,
        process.env.HUBSPOT_GROWTH_REPORT_URL,
        "Report survey - SuperOps"
      )
      setIsSubmitted(true)
    }
  }
  const handleAfterSubmit = () => {
    let tempScore = 0
    if (
      ThisModuleContent.cards[ThisModuleContent.cards.length - 2]?.text
        .length === Object.keys(answers).length
    ) {
      reportModuleAnswers[
        ThisModuleContent.cards[0].cardName.toLowerCase().replaceAll(" ", "-")
      ] = answers

      localStorage.setItem(
        "module-answers",
        JSON.stringify(reportModuleAnswers)
      )
      if (moduleNum == 4) {
        if (isSubmitted) {
          !completed && sendHubspotData()
          setStage("all-modules")
        } else {
          setShowTechError(true)

          // Scroll to the div with id "007"
          const errorElement = document.getElementById("007")
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" })
          }
        }
      }

      if (moduleNum == allModules?.length) {
        allModules?.map((item, idx) => {
          let moduleId = item?.cards?.[0].cardName
            ?.replaceAll(" ", "-")
            ?.toLowerCase()
          Object.entries(reportModuleAnswers[moduleId])?.map(([key, value]) => {
            tempScore += Number(
              item?.cards?.[item?.cards?.length - 2]?.multiChoice?.[key]
                ?.option?.[value]
            )
          })
        })
        !completed && sendHubspotData(tempScore)
        const email = JSON?.parse(localStorage.getItem("report-email"))
        window.location.href = `/result?email=${email}`
      } else {
        if (moduleNo != 4) {
          setStage("all-modules")
          !completed && sendHubspotData()
        }
      }
      setShowError(false)
    } else {
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const cardContent = ThisModuleContent?.cards[step - 1]

  const [totalTechnicians, setTotalTechnicians] = useState(1)
  const [error, setError] = useState("")
  const checkTechRange = value => {
    return value >= 1 && value <= 1000
  }

  const [showError, setShowError] = useState(false)
  const [showTechError, setShowTechError] = useState(false)
  const formatNumber = num => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`
    }
    return num
  }
  const firstBox = (
    <>
      <div className="d-flex flex-column individual-module  mx-auto">
        {ThisModuleContent?.cards.map(field => {
          switch (field.slug) {
            case "Type 1":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="type-1">
                    <p className="tag fw-600 fs-24 text-pink">
                      {field?.cardDescription}
                    </p>

                    <Row
                      className={`d-flex align-items-center ${
                        field?.image.length ? "" : ""
                      }`}
                    >
                      {field?.content.map(item => {
                        return (
                          <Col
                            lg={field?.image?.length ? 4 : 6}
                            className="content-cover"
                          >
                            {parse(item.html)}
                          </Col>
                        )
                      })}
                      {field?.image.length ? (
                        <Col className="img-col" lg={4}>
                          <img src={field?.image[0]?.url} className="w-100" />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>
                </Fade>
              )

            case "Type 2":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="type-2 h-100 ">
                    <p className="tag fs-24  fw-600 text-pink">
                      {field?.cardDescription}
                    </p>

                    <div className="xbox-2 d-flex gap-24  align-items-center">
                      <Col lg={5} className="content-cover">
                        {parse(field?.content[0]?.html)}
                      </Col>
                      <Col className="tip-col" lg={4}>
                        <div className="tip-box">
                          <p className="fw-600">{field?.text[0]}</p>
                          {parse(field?.content[1]?.html)}
                        </div>
                      </Col>
                    </div>
                  </div>
                </Fade>
              )

            case "Type 3":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="type-3 h-100">
                    <p className="tag fw-600  fs-16 text-pink">
                      {field?.cardDescription}
                    </p>
                    <div className="d-flex flex-wrap align-items-center justify-content-between h-90">
                      <Col lg={field?.image?.length ? 6 : 11} className="fs-18">
                        {parse(field?.content[0]?.html)}
                      </Col>
                      {field?.image[0] ? (
                        <Col lg={4}>
                          <img
                            src={field?.image[0]?.url}
                            className="w-100 my-40px"
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Fade>
              )

            case "Type 4":
              return (
                <Fade duration={500}>
                  <div className="header my-40px d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="type-4 my-40px d-flex flex-column gap-24">
                    {field?.content?.map((item, idx) => {
                      return (
                        <div className="d-flex align-items-center justify-content-center box mx-auto">
                          <div className="ques-no fs-32-em fw-bold">{`0${idx +
                            1}`}</div>
                          {parse(item?.html)}
                        </div>
                      )
                    })}
                  </div>
                </Fade>
              )
            case "Type 5":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="type-5  d-flex flex-column">
                    <p className="tag fw-600 fs-16 text-pink">
                      {field?.cardDescription}
                    </p>
                    <div className="d-flex flex-column gap-24 justify-content-center my-40px">
                      {field?.content?.map(item => {
                        return (
                          <div className="tip-box">{parse(item?.html)}</div>
                        )
                      })}
                    </div>
                  </div>
                </Fade>
              )

            case "get tech count":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="get-tech d-flex align-items-center h-100 my-auto">
                    <div className="child w-100">
                      <p className="tag fw-600 fs-16 text-pink">
                        {field?.text[0]}
                      </p>
                      <p className="font-roboto fw-bold fs-24">
                        {field?.text[1]}
                      </p>
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex flex-column my-40px  gap-24">
                          <input
                            type="number"
                            value={totalTechnicians}
                            min={1}
                            max={1000}
                            className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                            onChange={e => {
                              let tempValue = e.target.value

                              if (tempValue === "") {
                                setTotalTechnicians("")
                                setError("Minimum requirement: 1 technician")
                                return
                              }

                              if (checkTechRange(tempValue)) {
                                setTotalTechnicians(
                                  preventInitialZero(tempValue)
                                )
                                setError("")
                              }
                            }}
                          />
                          <Buttons
                            theme="btn btn-primary-new white-new"
                            text={field?.text[2]}
                            onClick={() => handleTechSubmit(totalTechnicians)}
                          />
                        </div>
                        {error && (
                          <p className="text-danger mt-2 fs-16">{error}</p>
                        )}{" "}
                        {/* Show error */}
                      </div>
                    </div>
                  </div>
                  <div id="007"></div>
                </Fade>
              )

            case "calc tech count":
              return (
                <>
                  {isSubmitted ? (
                    <Fade duration={500}>
                      <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                        <h3 className="font-roboto fw-bold module-heading">
                          {field.cardName}
                        </h3>
                      </div>
                      <div className="calc-tech d-flex align-items-center h-100 my-auto">
                        <div className="d-flex flex-column gap-24 my-40px justify-content-between align-items-center">
                          <div className="left w-100">
                            {parse(field?.content[0]?.html)}
                          </div>
                          <div className="center">
                            <img src={field?.image[0]?.url} className="w-100" />
                          </div>
                          <div className="right w-100">
                            {parse(field?.content[1]?.html)}
                            <span className="fw-bold">{` ${formatNumber(
                              totalTechnicians * 200000
                            )} ,`}</span>
                            {parse(field?.content[2]?.html)}
                          </div>
                        </div>
                      </div>
                    </Fade>
                  ) : (
                    <></>
                  )}
                </>
              )

            case "recommend":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="recommend my-auto">
                    <div className="d-flex flex-wrap gap-32 justify-content-between">
                      {field?.text?.map((item, idx) => {
                        return (
                          <div className="box w-100">
                            {field?.image[idx] && (
                              <div className="icon-wrap mb-20-em">
                                <img
                                  src={field?.image[idx]?.url}
                                  className="w-100"
                                />
                              </div>
                            )}
                            <p className="fw-600 fs-24 pb-20-em mb-20-em text">
                              {item}
                            </p>
                            <p className="desc-p">
                              {parse(field?.content[idx]?.html)}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Fade>
              )

            case "recommend-2":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="recommend-2 my-auto">
                    {field?.cardDescription ? (
                      <p className="tag  fs-24 fw-600 text-pink">
                        {field?.cardDescription}
                      </p>
                    ) : (
                      <></>
                    )}
                    <div className="recommend-2 d-flex gap-24">
                      {field?.text[0] ? (
                        <div className="left-box box fw-bold">
                          <p>{field?.text[0]}</p>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="right-box box">
                        {parse(field?.content[0]?.html)}
                      </div>
                    </div>
                  </div>
                </Fade>
              )

            case "recommend-3":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="recommend-3 ">
                    <p className="tag fw-600 fs-16 text-pink">
                      {field?.cardDescription}
                    </p>
                    <div className="d-flex flex-column gap-24 my-40px justify-content-between">
                      {field?.text?.map((item, idx) => {
                        return (
                          <div className="box w-100">
                            <div className="above-box d-flex align-items-center gap-24 mx-auto">
                              {field?.image[idx] && (
                                <div className="icon-wrap mb-20-em">
                                  <img
                                    src={field?.image[idx]?.url}
                                    className="w-100"
                                  />
                                </div>
                              )}
                              <p className="fw-600 fs-24 m-0 text">{item}</p>
                            </div>
                            <div className="below-box">
                              <p className="fs-16-em">
                                {parse(field?.content[idx]?.html)}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Fade>
              )

            case "assessment":
              return (
                <Fade duration={500}>
                  <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
                    <h3 className="font-roboto fw-bold module-heading">
                      {field.cardName}
                    </h3>
                  </div>
                  <div className="h-100 assm d-flex flex-column my-auto">
                    <p className="tag fw-600 fs-24 text-pink">
                      {field?.cardDescription}
                    </p>
                    <div className="assessment w-100">
                      {field.text.map((ques, idx) => {
                        return (
                          <Row className="que-ans flex-wrap mb-40-em">
                            <Col lg={6}>
                              <div className="d-flex gap-16">
                                <span className="ques-no font-roboto fs-24 fw-600 mr15">{`0${idx +
                                  1}`}</span>
                                <p className="font-roboto fs-24 fw-600">
                                  {ques}
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={5}
                              className="d-flex align-items-center gap-24 justify-content-between options"
                            >
                              {field?.multiOption[idx].option.map(
                                (option, optInd) => {
                                  return (
                                    <div className="position-relative  radio-wrapper cursor-pointer option">
                                      <input
                                        type="radio"
                                        id={`${option}${idx}-mobile`}
                                        name={`${option}${idx}-mobile`}
                                        value={option}
                                        checked={answers?.[idx] === optInd}
                                        onChange={() =>
                                          handleOptionChange(idx, optInd)
                                        }
                                        className=" m-0"
                                        disabled={completed}
                                      />
                                      <label
                                        for={`${option}${idx}-mobile`}
                                        className="checkbox-label d-flex align-items-center"
                                      >
                                        <span className="checkbox-name">
                                          <p className="one m-0 fw-500 fs-20-em">
                                            {option}
                                          </p>
                                        </span>
                                      </label>
                                    </div>
                                  )
                                }
                              )}
                            </Col>
                          </Row>
                        )
                      })}
                    </div>
                  </div>
                </Fade>
              )
            default:
              return null
          }
        })}
      </div>
      <div className="mob-bottom">
        <div className="top-box d-flex">
          <div className="module-no">
            <p>{`MODULE ${moduleNum}`}</p>
          </div>
          <div className="continue">
            <a
              onClick={() => handleAfterSubmit(totalTechnicians)}
              className="continue-btn cursor-pointer fs-16-em fw-bold"
            >
              {"CONTINUE"}
            </a>
            <p
              className={`error-message error-text-mob ${
                showError ? "fade-in-effect" : "fade-out-effect"
              }`}
            >
              {"Please answer all the above questions"}
            </p>
            <p
              className={`error-message error-text-mob ${
                showTechError ? "fade-in-effect" : "fade-out-effect"
              }`}
            >
              {"Please enter the tech count"}
            </p>
          </div>
        </div>
        {/* <div className="bottom-box d-flex">
          <p>PROGRESS</p>
          <progress
            id="file"
            className="module-progress w-100"
            value={Object.keys(reportModuleAnswers)?.length}
            max={allModules.length}
          />
        </div> */}
      </div>
    </>
  )

  const leftBox = (
    <div className="d-flex w-80-percent">
      <p className="font-white fs-16-em module-name m-0 fw-600">{`MODULE ${moduleNum}`}</p>
      <div className="d-flex align-items-center gap-8 progress-div">
        <p className="m-0 fs-16-em font-white fw-600">{"PROGRESS"}</p>
        <progress
          id="file"
          className="module-progress w-100"
          value={Object.keys(reportModuleAnswers)?.length}
          max={allModules.length}
        />
      </div>
    </div>
  )

  const topBox = (
    <div className="top-box d-flex">
      <div className="module-no w-75">
        <p className="font-white fs-16-em module-name m-0 fw-600">{`MODULE ${moduleNum}`}</p>
      </div>
      <div className="continue w-25">
        <a
          onClick={() => handleAfterSubmit()}
          className="continue-btn cursor-pointer fs-16-em fw-bold"
        >
          {"CONTINUE"}
        </a>
        <p
          className={`error-message error-text ${
            showError ? "fade-in-effect" : "fade-out-effect"
          }`}
        >
          {"Please answer all the above questions"}
        </p>
      </div>
    </div>
  )

  const bottomBox = (
    <div className="bottom-box">
      <progress
        id="file"
        className="module-progress w-100"
        value={Object.keys(reportModuleAnswers)?.length}
        max={allModules.length}
      />
    </div>
  )

  const rightBox = (
    <div className="position-relative w-100 d-flex justify-content-center">
      <a
        onClick={() => handleAfterSubmit()}
        className="continue-btn cursor-pointer fs-16-em fw-bold"
      >
        {"CONTINUE"}
      </a>
      <p
        className={`error-message error-text ${
          showError ? "fade-in-effect" : "fade-out-effect"
        }`}
      >
        {"Please answer all the above questions"}
      </p>
    </div>
  )

  return (
    <Skeleton
      firstBox={firstBox}
      rightBox={rightBox}
      leftBox={leftBox}
      removeSecondBox
      // topBox={topBox}
      // bottomBox={bottomBox}
      // removeSecondBox
      // mobSecondBox
    />
  )
}
const FinalResult = ({ setStage, data, allModules }) => {
  const moduleAnswers = JSON.parse(localStorage.getItem("module-answers"))

  const [overallScore, setOverallScore] = useState(0)
  const [grade, setGrade] = useState(0)

  useEffect(() => {
    let tempScore = 0
    if (
      moduleAnswers &&
      allModules?.length === Object.keys(moduleAnswers)?.length
    ) {
      allModules?.map((item, idx) => {
        let moduleId = item?.cards?.[0].cardName
          ?.replaceAll(" ", "-")
          ?.toLowerCase()
        Object.entries(moduleAnswers[moduleId])?.map(([key, value]) => {
          tempScore += Number(
            item?.cards?.[item?.cards?.length - 2]?.multiChoice?.[key]
              ?.option?.[value]
          )
        })
      })

      if (tempScore >= 70) setGrade(0)
      else if (tempScore >= 50) setGrade(1)
      else setGrade(2)

      setOverallScore(tempScore)
    } else {
      window.location.href = window.location.href.split("#")[0] + "#all-modules"
      setStage("all-modules")
    }
  }, [])

  const [showGreeting, setShowGreeting] = useState(false)

  const DownloadBadge = () => {
    downloadAsset(data?.image?.[grade]?.url, data?.text?.[grade])
    setTimeout(() => {
      setShowGreeting(true)
    }, 1000)
  }

  const firstBox = (
    <div className="final-result mx-auto">
      <div className="header d-flex gap-24 flex-wrap justify-content-between align-items-center w-100">
        <h3 className="font-roboto fw-bold module-heading">{data?.cardName}</h3>
      </div>
      <div className="d-flex align-items-center h-100">
        <div className="d-flex gap-16 justify-content-between w-100">
          <div className="badge-box text-center">
            <img src={data?.image?.[grade]?.url} className="badge-img" />
            <div className="d-flex gap-8 justify-content-center">
              <p className="fw-bold score">{"score"}</p>{" "}
              <p className="fw-bold score">{overallScore}</p>
            </div>
          </div>
          <div className="content-box">
            {showGreeting ? (
              <div className="greeting">{parse(data?.content?.[3]?.html)}</div>
            ) : (
              <>
                <p className="fw-bold fs-24 title mb-20-em pb-20-em">
                  {data?.text?.[grade]}
                </p>
                <div className="rich-text mb64">
                  {parse(data?.content?.[grade]?.html)}
                </div>
                <Buttons
                  theme="btn btn-primary-new white-new"
                  text={data?.slug}
                  onClick={() => DownloadBadge()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
  return <Skeleton firstBox={firstBox} removeSecondBox />
}

const Skeleton = ({
  firstBox,
  rightBox,
  leftBox,
  removeSecondBox,
  transparentBg,
  mobSecondBox,
  topBox,
  bottomBox,
}) => {
  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768)
    }

    checkMobileView() // Run on mount
    window.addEventListener("resize", checkMobileView)

    return () => {
      window.removeEventListener("resize", checkMobileView)
    }
  }, [])

  const content = (
    <div className="parent-box">
      <div className={`first-box ${removeSecondBox ? "only" : ""}`}>
        {firstBox}
      </div>

      {!removeSecondBox && (
        <div className="second-box d-flex">
          {!isMobileView && (
            <div className="left-box">
              <div className="child-box d-flex align-items-center justify-content-end">
                {leftBox}
              </div>
            </div>
          )}
          <div className="right-box d-flex align-items-center justify-content-center">
            {rightBox}
          </div>
        </div>
      )}

      {mobSecondBox && (
        <>
          {topBox}
          {bottomBox}
        </>
      )}
    </div>
  )

  if (isMobileView) {
    // For mobile view (less than 768px), only return parent-box content
    return content
  }

  return (
    <div
      className={`vheight-100 ${
        transparentBg ? "bg-without-color" : "bg-with-color"
      }`}
    >
      {content}
    </div>
  )
}

export default ReportsPage

export function GrowthReport() {
  const data = useStaticQuery(graphql`
    query growthReport {
      SuperOps {
        pages(where: { title: "Growth report" }) {
          seo {
            title
            description
            keywords
            image {
              url
            }
          }
          newField {
            ... on SuperOps_MultipleCard {
              __typename
              cards {
                cardName
                cardDescription
                slug
                text
                subtext
                links
                image {
                  url
                }
                content {
                  html
                }
                multiChoice {
                  option
                }
                multiOption {
                  option
                }
              }
            }
            ... on SuperOps_Card {
              __typename
              cardName
              cardDescription
              heading
              slug
              text
              title
              image {
                url
              }
              picture {
                url
              }
              content {
                html
              }
            }
          }
        }
      }
    }
  `)
  return data
}
